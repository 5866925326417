

















import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(_, { root }) {
    const state = reactive({
      items: [],
      loading: false,
    });

    const fetchSurveys = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/survey`)
        .then(({ data: { surveys } }) => {
          state.items = surveys
            .map((el: any) => ({
              id: el.id,
              name: el.name,
              isEnabled: el.isEnabled,
            }))
            .filter((item: any) => item.isEnabled);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchSurveys);
    return { state };
  },
});
